<template>
      <a-sub-menu :key="menuInfo.no" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <!-- <a-icon type="snippets" /> -->
          <a-icon type="book" />
          <span>{{ menuInfo.text }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children" :key="item.no" >
          <div style="display: flex; justify-content: space-between;">
            <div >
            <!-- <a-icon type="pie-chart" /> -->
            <!-- <a-icon type="google" /> -->
            <a-icon type="profile" />
            <span>{{ item.text }}</span>
            </div>
            <div v-if="isdelete">
              <a-icon type="close" title="删除" @click="deleteClick($event,item.no,item.text)"/>
            </div>
          </div>
          </a-menu-item>
          <sub-menu v-else :key="item.no" :menu-info="item" />
        </template>
      </a-sub-menu>
</template>
<script>
import { Menu } from 'ant-design-vue';
import SubMenu from "./eneMeasureGroupSubMenu.vue";
export default {
  name: 'SubMenu',
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
    fatherMethod: {
        type: Function,
        default: null
    },
    isdelete:{
      type:Boolean,
      default:true
    }
  }, 
  methods: {
    deleteClick(e,no,text) {
      e.stopPropagation();
     if(this.fatherMethod)
      this.fatherMethod(e,no,text);
    },
    // titleClick(e) {
    //   //e.stopPropagation();
    //   console.log('titleClick', e);
    // },
  },
   components: {
    'sub-menu': SubMenu,
  },
}
</script>