<!-- 数据统计-->
<template>
  <div id="energyPointStatistics" v-loading="loading" ref="main" :element-loading-text="$t('alarm.a0')">
    <!-- 页面标题 -->
    <div class="energyPointStatistics-title">
      <span>{{ $t("energy.enedata605") }}</span>
      <!-- {{ $t("energy.enedata001") }}站点能耗对比 -->
    </div>
    <a-row style="height: calc(100% - 30px);">
        <a-col :span="4" style="height: 100%;padding-bottom:5px;">
          <template>
              <div style="" class="point-tree-box">
                  <a-input-search style="margin-bottom: 8px" :placeholder='$t("energy.search")' @change="queryPoints" />
                  <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                    {{ $t("energy.enedata025") }}
                  </a-checkbox>
                  <a-tree
                    style=""
                    class="point-tree"
                    show-line
                    :tree-data="treePointLists"
                    :replaceFields="{children:'children', title:'name', key:'key' }"
                    checkable
                    v-model="checkedPoints"
                    :expanded-keys="extPointNoLists"
                    :auto-expand-parent="autoExpandParent"
                    @select="pointSelect"
                    @check="pointCheck"
                    @expand="expandChange"
                  >
                  </a-tree>
              </div>
          </template>
        </a-col>
        <a-col :span="16" style="padding: 0 2px 5px;height: 100%;">
             <!-- 查询条件1 -->
            <div class="display-between inquire">
              <div class="display-between-2 inquire-item">
                 <div class="display-column">
                    <span>{{ $t("energy.enedata075") }}</span>
                    <a-radio-group v-model="treeLevel" button-style="solid" @change="treeLevelChanges" >
                        <a-radio-button value="1" :disabled="userType===3 || userType===4">{{ $t("energy.enedata076") }}</a-radio-button>
                        <a-radio-button value="2" :disabled="userType===3 || userType===4">{{ $t("energy.enedata077") }}</a-radio-button>
                        <a-radio-button value="3">{{ $t("energy.enedata078") }}</a-radio-button>
                    </a-radio-group>
                </div>
                <div class="display-column">
                  <!-- 类型 -->
                  <span>{{ $t("energy.enedata002") }}</span>
                  <a-radio-group v-model="rpt" button-style="solid" @change="rptChange">
                    <a-radio-button  v-for="(itme, index) in this.rptKinds"
                        :key="index"
                        :value="itme.no"> {{ itme.text }}</a-radio-button>
                  </a-radio-group>
                </div>
                <!-- 计量对象 -->
                <!-- <div class="display-column">
                  <span>{{ $t("energy.enedata003") }}</span>
                  <a-select v-model="point" style="width: 360px" @change="handleChange">
                    <a-select-option
                        v-for="(itme, index) in this.pointLists"
                        :key="index"
                        :value="itme.no"
                        >
                        {{ itme.text }}
                      </a-select-option>
                  </a-select>
                </div>  -->
                <div class="display-column">
                  <!-- 显示对象 -->
                  <span>{{ $t("energy.enedata004") }}</span>
                  <a-radio-group v-model="chartKind" button-style="solid" @change="drawMyChart">
                    <a-radio-button value="line">{{ $t("energy.enedata013") }}</a-radio-button>
                    <a-radio-button value="bar">{{ $t("energy.enedata014") }}</a-radio-button>
                    <!-- <a-radio-button value="pie">{{ $t("energy.enedata015") }}</a-radio-button> -->
                  </a-radio-group>
                </div>
                <div class="display-column">
                    <span>{{ $t("energy.enedata271") }}</span>
                    <a-radio-group v-model="carbon" button-style="solid" @change="drawMyChart">
                        <a-radio-button value="0">{{ $t("energy.enedata009") }}</a-radio-button>
                        <a-radio-button value="1">{{ $t("energy.enedata443") }}</a-radio-button>
                    </a-radio-group>
                </div>
                
              </div>
              <a-button type="primary" @click="getRptData">{{ $t("energy.enedata006") }}</a-button>
              <!-- 查询 -->
            </div>
            <div style="height: calc(100% - 68px);background:#7682CE;">
                <template> 
                  <a-carousel arrows>
                    <div slot="prevArrow"  class="custom-slick-arrow" style="left: 10px;zIndex: 1;">
                      <a-icon type="left-circle" />
                    </div>
                    <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px">
                      <a-icon type="right-circle" />
                    </div>

                    <div style="height:100%;">
                      <div class="chart-box">
                        <div id="myChart" :style="myChartSize" ></div>
                      </div>

                    </div>
                      <div style="height:100%;">
                        <div class="table-box">
                          <a-table
                              :columns="myStatisticsData.tableData.columns"
                              :data-source="myStatisticsData.tableData.data"
                              :pagination="pagination"
                              :scroll="Yscroll"
                              size="small"
                            />
                        </div>
                      </div>
                  </a-carousel>
                </template>
            </div>
        </a-col>
         <a-col :span="4" style="height: 100%;padding-bottom:5px;">
            <div class="point-date-box">
                <div class="point-date" v-if="rpt=='3'">
                  <div class="display-column" style="padding:5px;border-bottom:1px solid #f5f5f5;">
                    <!-- 目标年度 -->
                    <span>{{ $t("energy.enedata005") }}</span>
                    <!--  @change="yearChange" -->
                    <a-select v-model="year"  :mode="mode" style="width: 120px"> 
                      <a-select-option
                          v-for="(itme, index) in this.yearList"
                          :key="index"
                          :value="itme"
                          >
                          {{ itme }}
                        </a-select-option>
                    </a-select>
                  </div>
                  <div class="display-column" style="padding:5px;border-bottom:1px solid #f5f5f5;">
                      <span>{{$t("energy.enedata398")}}</span>
                      <a-switch v-model="msel" @change="mselChange" :style="{'width':'60px','margin-top':'5px'}" />
                  </div>
                  <div class="display-column" style="padding:5px;border-bottom:1px solid #f5f5f5;">
                      <span>{{$t("energy.enedata399")}}</span>
                      <a-select v-model="month" style="width: 120px" :disabled="defaultStatistic==0" >
                        <a-select-option
                            v-for="(itme, index) in this.monthList"
                            :key="index"
                            :value="itme.no"
                            >
                            {{ itme.text }}
                          </a-select-option>
                      </a-select>
                  </div>
                </div>
                <div class="point-date"  v-if="rpt=='2'">
                    <div class="date-box1">
                      <span>{{ $t("energy.enedata005") }}：</span>
                      <a-select v-model="year" style="width: 120px" @change="monthRptYearChange"  :disabled="this.measurePointIds.length!=1">
                        <a-select-option
                            v-for="(itme, index) in this.yearList"
                            :key="index"
                            :value="itme"
                            >
                            {{ itme }}
                          </a-select-option>
                      </a-select>
                    </div>
                    <div class="date-box">{{$t("energy.enedata442")}}1：<a-month-picker  v-model="mon" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}2：<a-month-picker  v-model="tue" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}3：<a-month-picker  v-model="wed" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}4：<a-month-picker  v-model="thu" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}5：<a-month-picker  v-model="fri" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}6：<a-month-picker  v-model="sat" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}7：<a-month-picker  v-model="sun" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}8：<a-month-picker  v-model="mon8" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}9：<a-month-picker  v-model="mon9" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}10：<a-month-picker  v-model="mon10" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}11：<a-month-picker  v-model="mon11" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                    <div class="date-box">{{$t("energy.enedata442")}}12：<a-month-picker  v-model="mon12" :disabled="this.measurePointIds.length!=1" style="width:120px;"/></div>
                </div>
                <div class="point-date"  v-if="rpt=='1'">


                    <div class="date-box1">{{$t("energy.enedata440")}}：<a-week-picker :placeholder='$t("energy.enedata440")' v-model="week" @change="weekChange" :disabled="this.measurePointIds.length!=1"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}1：<a-date-picker  v-model="mon"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}2：<a-date-picker  v-model="tue" :disabled="this.measurePointIds.length!=1"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}3：<a-date-picker  v-model="wed" :disabled="this.measurePointIds.length!=1"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}4：<a-date-picker  v-model="thu" :disabled="this.measurePointIds.length!=1"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}5：<a-date-picker  v-model="fri" :disabled="this.measurePointIds.length!=1"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}6：<a-date-picker  v-model="sat" :disabled="this.measurePointIds.length!=1"/></div>
                    <div class="date-box">{{$t("energy.enedata439")}}7：<a-date-picker  v-model="sun" :disabled="this.measurePointIds.length!=1"/></div>
                </div>
            </div>
        </a-col>
    </a-row>
   


  </div>
</template>

<script>
import { getEnergySitesStatisticsData } from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import moment from "moment";
const colors=['#7682CE','#d1b7dd','#86c9e3','#99d080','#607aca','#fc8958','#ef6b6b','#f7df1e'];
import { EleResize } from "../../../utils/esresize";
export default {
  data() {
    return {
      loading:false,
      rpt: "3",
      chartKind: "line",
      point: "",
      year:undefined,
      fiscalYear:undefined,
      rptKinds:[],
      pointLists:[],
      treePointLists:[],
      treePointNoLists:[],
      extPointNoLists:[],
      autoExpandParent:true,
      dataLists:[],
      chartData:null,
      yearList:[],
      date:"",
      measurePoint:null,
      ycategorys:[],
      mcategorys:[],
      dcategorys:[],
      columns:[],
      pagination:false,
      Yscroll: { x: 1100,y:240 },
      difPoint:false,
      difYear:false,
      msel:false,
      defaultStatistic:0,
      month:"1",
      monthList:[],
      monthMap:null,
      checkedPoints:[],
      measurePointIds:[],
      week:moment(),
      mon:moment(),
      tue:undefined,
      wed:undefined,
      thu:undefined,
      fri:undefined,
      sat:undefined,
      sun:undefined,
      mon8:undefined,
      mon9:undefined,
      mon10:undefined,
      mon11:undefined,
      mon12:undefined,
      days:[moment()],
      myStatisticsData:{
        tableData:{
          columns:[],
          data:[]
        },
        chartData:{
            title:"",
            unit:"",
            xunit:"",
            yunit:"",
            legends: Array(),
            categorys: Array(),
            series: Array(),
            forecastSeries: Array(),
            visualMap:undefined,
            legend:undefined,
            xAxis:Array(),
            yAxis:Array(),

            accumYAxis:Array(),
            accumSeries: Array(),
            accumLegends: Array(),

            pieSeries: Array(),
            pieData: Array(),
            products: Array(),
            pieYAxis: Array(),

        },
        totalData:[],
      },
      myChartSize:{width: '100%', height: '0px'},
      mode:"multiple",
      indeterminate:false,
      checkAll:false,
      treeLevel:"2",
      myChart:undefined,
      carbon:"0",
      userType:0,
    };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.initPage();
    }
  },
  mounted() {
    // this.initPage();
    this.userType = JSON.parse(sessionStorage.getItem('user')).userType;
    console.log(this.userType);
    if(this.userType==3 || this.userType==4){
        this.treeLevel = '3';
    }
    //实现自适应部分
    this.resizeChart();
    window.addEventListener("resize", () => this.resizeChart());
    
  },
  methods: {
    expandChange(expandedKeys, expanded){
      console.log('expanded',expanded);
      this.extPointNoLists = expandedKeys;
      this.autoExpandParent = false;
    },
    treeLevelChanges(){
      this.initPage();
    },
    onCheckAllChange(e){
      let bol = e && e.target.checked;
      Object.assign(this, {
        checkedPoints: bol ? this.treePointNoLists : [],
        indeterminate: false,
        checkAll: bol,
      });
    },
    resizeChart(){
      if(this.$refs.main){
        let height = this.$refs.main.clientHeight;
        let tableHeight = height - 180;
        // console.log("resizeChart=",tableHeight);
        this.myChartSize.height = (height-160)+"px";
        this.Yscroll.y = tableHeight-20;
      }
    },
    initPage(){
      this.myStatisticsData = {
        tableData:{
          columns:[],
          data:[]
        },
        chartData:{
            title:"",
            unit:"",
            xunit:"",
            yunit:"",
            legends: Array(),
            categorys: Array(),
            series: Array(),
            forecastSeries: Array(),
            visualMap:undefined,
            legend:undefined,
            xAxis:Array(),
            yAxis:Array(),

            accumYAxis:Array(),
            accumSeries: Array(),
            accumLegends: Array(),

            pieSeries: Array(),
            pieData: Array(),
            products: Array(),
            pieYAxis: Array(),

        },
        totalData:[],
      };
      let params = {
        action:0,
        clientId: this.$store.getters.clientId,
        sitegrId: this.$store.getters.sitegrId,
        siteId: this.$route.query.id,
        tenantId: 0,
      };
      if(this.treeLevel=='1'){
        params = {
          action:0,
          clientId: this.$store.getters.clientId,
          sitegrId: 0,
          siteId: 0,
          tenantId: 0,
        };
      } else if(this.treeLevel=='2'){
        params = {
          action:0,
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: 0,
          tenantId: 0,
        };
      }
      console.log(params);
      this.loading = true;
      this.yearList = [];
      this.fiscalYear = [];
      this.ycategorys = [];
      this.mcategorys = [];
      this.dcategorys = [];
      this.monthList  = [];
      this.checkedPoints = [];
      this.treePointNoLists = [];
      this.checkAll = false;
      this.indeterminate = false;
      this.selectedKeys = [];
      this.autoExpandParent = true;
      getEnergySitesStatisticsData(params)
      .then((res) => {
        console.log(res);
        this.loading = false;
        this.rptKinds = res.data.rptKinds;
        this.pointLists = res.data.siteMeasurementPoints;
        this.treePointLists = [...this.pointLists];
        // for(let i=0;i<this.treePointLists.length;i++){
        //     this.treePointNoLists.push(this.treePointLists[i].key);
        // }
        this.setTreePointNoLists(this.treePointLists,'POINT');
        console.log('POINT',this.treePointNoLists);
        this.setTreePointNoLists(this.treePointLists,'EXPAND');
        console.log('EXPAND',this.extPointNoLists);
        // this.point = this.pointLists[0].key;
        this.yearList = res.data.yearList;
        this.fiscalYear = res.data.fiscalYear;
        this.year = res.data.fiscalYear;
        this.ycategorys = res.data.ycategorys;
        this.mcategorys = res.data.mcategorys;
        this.dcategorys = res.data.dcategorys;
        this.monthList  = res.data.monthList;
        this.drawDefultChart();        
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    setTreePointNoLists(treePointLists,flag){
       if(treePointLists && treePointLists.length>0){
         for(let i=0;i<treePointLists.length;i++){
              if('POINT'===flag){
                if(treePointLists[i].level === 'pnt'){
                    this.treePointNoLists.push(treePointLists[i].key);
                }else{
                    this.setTreePointNoLists(treePointLists[i].children,flag);
                }
              }else{
                if(treePointLists[i].level != 'pnt'){
                    this.extPointNoLists.push(treePointLists[i].key);
                    this.setTreePointNoLists(treePointLists[i].children,flag);
                }else{
                }
              }
          }
       }
    },
    getRptData(){
      let measurePointIds = new Array();
      if(this.checkedPoints==null || this.checkedPoints.length<=0){
        this.$message.error(this.$t('energy.enedata533'));
        return;
      }
      this.checkedPoints.forEach(element => {
        if(element.indexOf('gr') >=0 || element.indexOf('client') >=0 || element.indexOf('sitegr') >=0 || element.indexOf('site') >=0 || element.indexOf('eqm') >=0){

        }else{
          measurePointIds.push(element);
        }
      });
      // measurePointIds = [...this.checkedPoints];
      let yearList = new Array();
      let dataTimes = new Array();
      let v = parseInt(this.rpt);
      if(v==3){
        console.log(typeof this.year);
        console.log(typeof this.year.length);
        if(this.year.length){

          yearList.push(...this.year);
        }else{
          yearList.push(this.year);
        }
      }else if(v==2){
         this.days = new Array();
        if(this.mon){
          this.days.push(this.mon)
        }
        if(this.tue){
          this.days.push(this.tue)
        }
        if(this.wed){
          this.days.push(this.wed)
        }
        if(this.thu){
          this.days.push(this.thu)
        }
        if(this.fri){
          this.days.push(this.fri)
        }
        if(this.sat){
          this.days.push(this.sat)
        }
        if(this.sun){
          this.days.push(this.sun)
        }

        if(this.mon8){
          this.days.push(this.mon8)
        }
        if(this.mon9){
          this.days.push(this.mon9)
        }
        if(this.mon10){
          this.days.push(this.mon10)
        }
        if(this.mon11){
          this.days.push(this.mon11)
        }
        if(this.mon12){
          this.days.push(this.mon12)
        }
        if(this.days==null || this.days.length<=0){
            return;
        }
        this.days.forEach(function(item){
            dataTimes.push(item.format('YYYY/MM'));
        });
      }else{
        this.days = new Array();
        if(this.mon){
          this.days.push(this.mon)
        }
        if(this.tue){
          this.days.push(this.tue)
        }
        if(this.wed){
          this.days.push(this.wed)
        }
        if(this.thu){
          this.days.push(this.thu)
        }
        if(this.fri){
          this.days.push(this.fri)
        }
        if(this.sat){
          this.days.push(this.sat)
        }
        if(this.sun){
          this.days.push(this.sun)
        }
        if(this.days==null || this.days.length<=0){
            return;
        }
        this.days.forEach(function(item){
            dataTimes.push(item.format('YYYY/MM/DD'));
        });
      }

      let params = {
        action:1,
        clientId: this.$store.getters.clientId,
        siteId: this.$route.query.id,
        sitegrId: this.$store.getters.sitegrId,
        tenantId: 0,
        reportType: this.rpt,
        measurePointIds: measurePointIds,
        yearList:yearList,
        dataTimes:dataTimes,
        defaultStatistic:this.defaultStatistic,
        month:this.month,
        carbon:this.carbon,
      };
      console.log(params);
      this.loading = true;
    
      getEnergySitesStatisticsData(params)
      .then((res) => {
        console.log("getEnergySitesStatisticsData result:",res);
        this.loading = false;
        let tableDatas = new Array();
        let columns = new Array();
        if(res.data.tableData){
           columns.push({
              "title": this.$t('energy.enedata441'),//"设备/信号名称",
              "dataIndex": "name",
              "key": "name",
              "width": 250,
              "fixed": "left"
          });

          tableDatas = res.data.tableData.data;
          columns.push(...res.data.tableData.columns);
        }

        this.myStatisticsData.tableData.columns = [...columns];
        this.myStatisticsData.tableData.data = [...tableDatas];
        console.log('columns',this.myStatisticsData.tableData.columns);
        console.log('tableData',this.myStatisticsData.tableData.data);
        if(res.data.chartData){
          this.myStatisticsData.chartData.xAxis = res.data.chartData.xAxis;
          this.myStatisticsData.chartData.yAxis = res.data.chartData.yAxis;
          this.myStatisticsData.chartData.legend = res.data.chartData.legend;
          this.myStatisticsData.chartData.series = res.data.chartData.series;
          this.myStatisticsData.chartData.accumSeries = res.data.chartData.accumSeries;
          this.myStatisticsData.chartData.accumYAxis = res.data.chartData.accumYAxis;

          this.myStatisticsData.chartData.accumLegends = res.data.chartData.accumLegends;

          this.myStatisticsData.chartData.pieSeries = res.data.chartData.pieSeries;
          this.myStatisticsData.chartData.pieData   = res.data.chartData.pieData;
          this.myStatisticsData.chartData.products  = res.data.chartData.products;
          this.myStatisticsData.chartData.pieYAxis  = res.data.chartData.pieYAxis;

          this.myStatisticsData.chartData.title = res.data.chartData.title;

          if(this.chartKind==='pie'){
              let option = this.getHourlyPieChartOption(res.data.chartData);
              this.drawPieChart(option);
          }else{
              let option = this.getHourlyChartOption(res.data.chartData);
              this.drawEChart(option);
          }
         
          if(res.data.carbonErr==2){
            let msg = '';
            let msgs = res.data.messageList;
            msgs.forEach(element => {
              msg+=element+"\n";
            });
            this.$message.success(msg);
          }
        }else{
          this.drawDefultChart();
        }
       
      }).catch((err) => {
        this.loading = false;
        console.log(err);
      });
    },
    rptChange(e){
     
      this.drawDefultChart();
      this.columns=[];
      this.dataLists=[];
      if(this.checkedPoints==null || this.checkedPoints.length<=0){
        return;
      }
      this.getRptData();
    },
    yearChange(e){
        console.log(`yearChange = ${e}`);
        this.getRptData();
    },
    dateSelect(date){
        var a = moment(date);
        let v = parseInt(this.rpt);
        if(v==1){
          this.date = a.format('YYYY/MM/DD');
          console.log(this.date);
        }else if(v==2){
          this.date = a.format('YYYY/MM');
          console.log(this.date);
        }
        this.getRptData();
    },
    disabledDate(startValue) {
      console.log(`disabledDate1 = ${startValue}`);
      const endValue = moment();
      console.log(`disabledDate2 = ${endValue}`);
      if (!startValue || !endValue) {
        return false;
      }
      return (
        startValue.valueOf() > endValue.valueOf() ||
        startValue >= moment().endOf()
      );
    },
    drawDefultChart(){
      // let option =  this.getDefaultOption();
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById("myChart"));
      // option && myChart.setOption(option, true);
      // myChart.resize();
      // this.resizeChart();
       // 基于准备好的dom，初始化echarts实例
      this.myStatisticsData.tableData.data=[];
      if(this.myChart){
        this.myChart.dispose();
      }
    },
    getDefaultOption(){
      let category =  ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      if(parseInt(this.rpt)==3){
        category = this.ycategorys;
      }else if(parseInt(this.rpt)==2){
        category = this.mcategorys;
      }else if(parseInt(this.rpt)==1){
        category = this.dcategorys;
      }
     let option = {
          xAxis: {
              type: 'category',
              data: category,
          },
          yAxis: {
              type: 'value'
          },
          legend:{

          },
          series: [{
              data: [0],
              type: 'line',
              smooth: true
          }]
      };
      console.log(option);
      return option;
    },
    mselChange(){
      console.log("mselChange",this.msel);
      if(this.msel){
        this.defaultStatistic = 1;
      }else{
        this.defaultStatistic = 0;
      }
    },
    pointSelect(selectedKeys, info){
      console.log('selected', selectedKeys, info);
      // console.log(JSON.stringify(info))
      if(info.selected){
        let key = info.node.$vnode.data.key;
        this.checkedPoints.push(key);
      }else{
        //this.checkedPoints.reduce
        let key = info.node.$vnode.data.key;
        let arr = new Array();
        this.checkedPoints.forEach(element => {
          if(element!=key){
            arr.push(element);
          }
        });

        this.checkedPoints = arr;
      }
      this.clearPointDate();
    },
    pointCheck(checkedKeys, info){
      console.log('onCheck', checkedKeys, info);
      let measurePointIds = new Array();
      checkedKeys.forEach(element => {
        if(element.indexOf('gr') >=0 || element.indexOf('client') >=0 || element.indexOf('sitegr') >=0 || element.indexOf('site') >=0 || element.indexOf('eqm') >=0){

        }else{
          measurePointIds.push(element);
        }
      });
      this.measurePointIds = measurePointIds;
      this.clearPointDate();
    },
    clearPointDate(){

      this.indeterminate = !(!this.measurePointIds.length) && (this.measurePointIds.length < this.treePointNoLists.length);
      if(this.measurePointIds.length>0){
        this.checkAll = (this.measurePointIds.length === this.treePointNoLists.length);
      }else{
        this.checkAll = false;
      }

      if(this.measurePointIds.length===1){
        if(parseInt(this.rpt)===3){
             this.mode="multiple";
             this.year = this.fiscalYear;
        }
      }else{
        if(parseInt(this.rpt)===1){
          this.tue = undefined;
          this.wed = undefined;
          this.thu = undefined;
          this.fri = undefined;
          this.sat = undefined;
          this.sun = undefined;
        }else if(parseInt(this.rpt)===2){
          this.tue = undefined;
          this.wed = undefined;
          this.thu = undefined;
          this.fri = undefined;
          this.sat = undefined;
          this.sun = undefined;
          this.mon8 = undefined;
          this.mon9 = undefined;
          this.mon10 = undefined;
          this.mon11 = undefined;
          this.mon12 = undefined;
        }else if(parseInt(this.rpt)===3){
          this.mode="default";
          // this.year = undefined;
          this.year = this.fiscalYear;
        }
      }
      
    },
    subQueryPoints(point,value){
        if(point.children && point.children.length>0 ){
             let arr = new Array();
             for (let i = 0; i < point.children.length; i++) {
                let child = point.children[i];
                if(child.level === 'pnt'){
                  if(new String(child.name).indexOf(value)>=0){
                    arr.push(child);
                  }
                }else{
                  this.subQueryPoints(child,value);
                  if(child.children.length>0 ){
                    arr.push(child);
                  }
                }
              }
              point.children = arr;
        }
    },
    queryPoints(e){
      this.checkedPoints = [];
      this.measurePointIds = [];
      this.checkAll = false;
      this.indeterminate = false;
      const value = e.target.value;
      console.log("queryPoints",value);
      console.log("points",this.pointLists);
      let treePointLists = new Array();
      if(value===''){
          treePointLists = this.pointLists;
      }else{
          let points = JSON.parse(JSON.stringify(this.pointLists));
          // points.push(...this.pointLists) ;
          for (let i = 0; i < points.length; i++) {
            let cc = points[i];
            this.subQueryPoints(cc,value);
            if(cc.children.length>0 ){
              treePointLists.push( cc);
            }
          }
         
      }
      console.log("queryPoints treePointLists",treePointLists);

      this.treePointLists = treePointLists;
      this.setTreePointNoLists(this.treePointLists,'EXPAND');
      console.log('queryPoints EXPAND',this.extPointNoLists);
      this.autoExpandParent = true;
    },
    monthRptYearChange(e){
        console.log(`monthRptYearChange = ${e}`);
        let now = moment();
        let star = new moment(e+"/01/01");;
        let len = 12;
        let days = new Array();
        if(e===now.year()){
          len  =  now.month()+1;
        }

        for(let i = 0;i<len;i++){
          let day = new moment(star);
          day = day.add(i, 'months');
          days.push(day);
        }
  
        this.mon = days.length>0?days[0]:undefined;
        this.tue = days.length>1?days[1]:undefined;
        this.wed = days.length>2?days[2]:undefined;
        this.thu = days.length>3?days[3]:undefined;
        this.fri = days.length>4?days[4]:undefined;
        this.sat = days.length>5?days[5]:undefined;
        this.sun = days.length>6?days[6]:undefined;
        this.mon8 = days.length>7?days[7]:undefined;
        this.mon9 = days.length>8?days[8]:undefined;
        this.mon10 = days.length>9?days[9]:undefined;
        this.mon11 = days.length>10?days[10]:undefined;
        this.mon12 = days.length>11?days[11]:undefined;
        this.days = [...days];
        
    },
    weekChange(date,str){
      if(date!=null){
        let num = date.weekday();
        let star = date.subtract(num, 'days');
        let days = new Array();
        for(let i = 0;i<7;i++){
          let day = new moment(star);
          day = day.add(i, 'days');
          days.push(day);
        }
  
        this.mon = days[0];
        this.tue = days[1];
        this.wed = days[2];
        this.thu = days[3];
        this.fri = days[4];
        this.sat = days[5];
        this.sun = days[6];
        this.days = [...days];

      }else{
        this.mon = undefined;
        this.tue = undefined;
        this.wed = undefined;
        this.thu = undefined;
        this.fri = undefined;
        this.sat = undefined;
        this.sun = undefined;
        this.days = [];
      }
    },
    getHourlyChartOption(chart){
        chart.series.forEach(element => {
            element.type = this.chartKind;
        });
        let series = new Array();
        let yAxis = new Array();
        let yAxisIdxMap = new Map();
        let legends = new Array();
        chart.legend.data
        if(this.chartKind==='bar'){
          let yAxisIdx = 0;
          for (let index = 0; index < chart.yAxis.length; index++) {
              let element = chart.yAxis[index];
              element.position='left';
              
              
              // if(index>0)
              //   element.nameLocation = 'start';
              // else
              //   element.nameLocation = 'end';
              yAxis.push(element);
              yAxisIdxMap.set('NORMAL_'+element.unit,yAxisIdx);
              yAxisIdx++;
          }
          for (let index = 0; index < chart.accumYAxis.length; index++) {
              let element = chart.accumYAxis[index];
              element.position='right';
              // if(index>0)
              //   element.nameLocation = 'start';
              // else
              //   element.nameLocation = 'end';
              yAxis.push(element);
              yAxisIdxMap.set('ACCUM_'+element.unit,yAxisIdx);
              yAxisIdx++;
          }
          console.log('yAxisIdxMap',yAxisIdxMap);
          
      
          for (let index = 0; index < chart.accumSeries.length; index++) {
            let element = chart.accumSeries[index];
            let key = 'ACCUM_'+element.unit;
            let myAxisIdx = yAxisIdxMap.get(key);
            console.log('key',key,myAxisIdx);
            if(myAxisIdx){
              element.yAxisIndex = myAxisIdx;
            }else{
              element.yAxisIndex = chart.yAxis.length;
            }
          }

          series.push(...chart.series);
          series.push(...chart.accumSeries);

          for (let index = 0; index < chart.legend.data.length; index++) {
              legends.push(chart.legend.data[index]);
              legends.push(chart.accumLegends[index]);
          }
        }else{
          series.push(...chart.series);
          for (let index = 0; index < chart.yAxis.length; index++) {
              let element = chart.yAxis[index];
              if(index%2==0){
                element.position='left';
              }else{
                element.position='right';
              }
              element.nameLocation = 'end';
              yAxis.push(element);
             
          }
          legends.push(...chart.legend.data);
        }

        let loffset = 0;
        let roffset = 0;
        let grdRight = '';
        let grdR = 0;
        let grdLeft = '';
        let grdL = 0;
        let grdWidth = 'auto';
        for (let index = 0; index < yAxis.length; index++) {
          const element = yAxis[index];
          
          
          if('right'==element.position){
            element.offset = roffset;
            roffset+=100;
            if(roffset>=100){
              grdR+=10;
            }
          }else if('left'==element.position){
            element.offset = loffset;
            loffset +=100;
            if(loffset>=100){
              grdL+=10;
            }
          }

        }
        let grid = {};
        if(grdR>0){
          grid.right = grdR+'%';
          // grdRight=grdR+'%';
        } 
        if(grdL>0){
           grid.left = grdL+'%';
          // grdLeft =grdL+'%';
        } 
        grdWidth = (100-grdR-grdL)+'%';

        let title = chart.title;
        let option = {
          backgroundColor: '#ffffff',
          title: {
              text: title,
              textStyle: {
                color: colors[0],
              },
          },
          color: colors,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          grid: grid,
          toolbox: {
            show: true,
            orient: 'horizontal',
            left: 'right',
            top: 'top',
            feature: {
              mark: { show: true },
              dataView: { show: true, readOnly: false },
              magicType: { show: true, type: ['line', 'bar', 'stack'] },
              restore: { show: true },
              saveAsImage: { show: true }
            }
          },
          legend:{
            data: legends,
            orient: 'horizontal',
            type: "scroll",
            top: "bottom",
            left: "left",
          },
          series:series,
          xAxis:chart.xAxis,
          yAxis:yAxis,
        };

        return option;
    },
    getHourlyPieChartOption(chart){
        let rseries = chart.pieSeries;
        let title   = chart.title;
        let products = chart.products;
        let formatter = '{rcs|{b}：{@'+products[1]+'} ({d}%)}'; //'+this.myStatisticsData.chartData.unit+'
        let sdata = chart.pieData;
        let yAxis = chart.pieYAxis;

        let option = {
          backgroundColor: '#ffffff',
          title: {
              text: title,
              textStyle: {
                color: colors[0],
              },
          },
          color:colors,
          legend: {
                orient: 'vertical',
                type: "scroll",
                top: "top",
                left: "right",
          },
          tooltip: {
              trigger: 'axis',
              showContent: true,
              axisPointer: {
                type: 'cross'
              },
              position: function (pos, params, el, elRect, size) {
                var obj = { top: 10 };
                obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
                return obj;
              }
          },
          dataset: {
            source: [
              products,
              ...sdata
            ]
          },
          xAxis: { type: 'category' },
          yAxis: yAxis,
          grid: { top: '60%' },
          series: [
            ...rseries,
            {
              type: 'pie',
              id: 'pie',
              radius: '30%',
              center: ['50%', '25%'],
              emphasis: {
                focus: 'self'
              },
              top:20,
              label: {
                alignTo: 'labelLine',
                formatter: formatter,
                rich:{rcs:{padding: 20,height: 30}}
              },
              encode: {
                itemName: 'product',
                value: products[1],
                tooltip:products[1]
              },
              
            },
          ]
      };

      return option;
    },
    
    drawEChart(option){
      // 基于准备好的dom，初始化echarts实例
      if(this.myChart){
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("myChart"));//,'dark'
      // 绘制图表
      // this.myChart.on('click', function (event) {
      //     console.log("event >>>",event);
      //      if (event.data) {
      //        console.log("event data>>>",event.data);
      //      }
      // });
      console.log(">>>",option);
      this.resizeChart();
      option && this.myChart.setOption(option, true);
      this.myChart.resize();
      let resizeDiv = document.getElementById("myChart");
      // const mmchart = this.myChart;
      let that = this;
      let listener = () => {
        that.myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
      
    },
    drawPieChart(option){
     if(this.myChart){
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("myChart"));//,'dark'
      // const mmchart = this.myChart;
      let that = this;
      
      option && that.myChart.setOption(option, true);
      that.myChart.on('updateAxisPointer', function (event) {
        const xAxisInfo = event.axesInfo[0];

        console.log('xAxisInfo',xAxisInfo)
        if (xAxisInfo) {
          // const dimension = products[xAxisInfo.value+1];
          const dimension = xAxisInfo.value+1;
          that.myChart.setOption({
            series: {
              id: 'pie',
              type:'pie',
              label: {
                formatter: '{b}：{@[' + dimension + ']} ({d}%)' //'+unit+'
              },
              encode: {
                value: dimension,
                tooltip: dimension
              }
            }
          });
        }
      });
      that.myChart.resize();
      let resizeDiv = document.getElementById("myChart");
      
      let listener = () => {
        that.myChart.resize();
      };
      EleResize.on(resizeDiv, listener);
    },
    drawMyChart(e){
      let kind = e.target.value;
      console.log('drawMyChart',kind);
      if(this.measurePointIds.length>0 && this.myStatisticsData.chartData.series.length>0){
        if(kind=='pie'){
          let option = this.getHourlyPieChartOption(this.myStatisticsData.chartData);
          this.drawEChart(option);
        }else{
          let option = this.getHourlyChartOption(this.myStatisticsData.chartData);
          this.drawEChart(option);
        }
      }
    }  
  },
  
  destroyed(){
    window.removeEventListener("resize", () => this.resizeChart());
  },
  components: {
    'sub-menu': SubMenu,
  },
};
</script>

<style scoped>
.show{
 display: block;
}
.hide{
  display:none;  
}
#energyPointStatistics {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
}
.energyPointStatistics-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px;
  color: #3e3a39;
}
.energyPointStatistics-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #7585cd;
  margin-right: 20px;
}
.display-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.display-between-2 {
  display: flex;
  justify-content: flex-start;
}
.display-column {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.display-column span {
  font-size: 14px;
  margin-bottom: 5px;
}
.inquire {
  padding:0 10px;
  margin-bottom: 10px;
}
.inquire-item {
  width: 100%;
}
.chart-box{
  /* background: #fff; */
  /* height: calc(100% -200px); */

  height: 100%;
  padding: 5px;
  margin: 10px 5px;
}


.table-box{
  height: 100%;
  padding: 5px;
  margin: 10px 5px;
  background: #fff;
} 
.date-box{
   font-size: 14px;
   padding: 5px;
   border-bottom: 1px solid #f5f5f5;
}
.date-box:last-child{border:none}

.date-box1{
   padding: 10px 5px;
   font-size: 14px;
   border-bottom: 1px solid #f5f5f5;
}
.date-box1:last-child {border-bottom: 1px solid #f5f5f5;}
.ant-carousel >>> .slick-slide {
  text-align: center;
  height: 100%;
  /* line-height: 160px; */
  background: #7682CE;
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.point-tree-box{width: 100%;height: 100%;padding:10px;border: 1px solid #d9d9d9 ;}
.point-tree{width: 100%;height:calc(100% - 60px);overflow: auto;}
.point-date-box{width: 100%;height: 100%;padding:10px;border: 1px solid #d9d9d9 ;}
.point-date{width: 100%;height:100%;overflow: auto;}
.ant-switch-checked {
    background-color: #7682CE;
}
</style>